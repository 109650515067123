import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size, spaceX } from 'driverama-core/styles/spacing'
import { CarCardGrid } from 'driverama-core/components/carCard/grid/CarCardGrid'
import { shadow } from 'driverama-core/styles/variables'
import { invisibleScrollbars } from 'driverama-core/styles/common'
import TrackSmallRight from 'images/TrackSmallRight.svg'

export const SContainer = styled.div`
  position: relative;
  height: auto;
  z-index: 1;
`

export const SHeader = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 1;
    grid-column-end: col-end 12;
  }
`

export const SList = styled.div`
  grid-column-start: col-left;
  grid-column-end: col-right;

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 0;

  margin-bottom: 0;

  ${invisibleScrollbars}

  &:before {
    @media ${media.gt('MD')} {
      content: '';
      display: block;
      height: 1px;
      min-width: 100%;
    }

    @media ${media.gt('tablet')} {
      min-width: calc(
        (min(100vw, var(--max-container-width, 100vw)) - ${size(320)}) / 2
      );
    }
  }

  &:after {
    content: '';
    display: block;
    height: 1px;
    margin-left: ${size(10)};

    min-width: 100vw;
  }

  ${spaceX(10)}

  @media ${media.gt('tablet')} {
    flex-direction: row;
    grid-column-start: col-left;
    scroll-padding-left: calc(((100vw - ${size(320)}) / 2) - ${size(5)});
  }
`

export const SCarContainer = styled.div`
  min-width: ${size(83)};
  padding: ${size(5)} ${size(4)} ${size(10)};
  scroll-snap-align: start;
  margin-left: 0 !important;
  position: relative;
`

export const SCar = styled(CarCardGrid)`
  box-shadow: ${shadow(3)};
`

export const STrack = styled(TrackSmallRight)`
  position: absolute;
  top: calc(50% - ${size(112)});
  left: calc(50% + ${size(84)});

  pointer-events: none;

  z-index: -1;
`

export const SDesktopBrowseAll = styled.div`
  @media ${media.lte('mobile')} {
    display: none;
  }
`

export const SMobileBrowseAll = styled.div`
  grid-column-start: col-1;
  grid-column-end: col-4;
  margin: ${size(3)} 0 0 auto;

  @media ${media.gt('mobile')} {
    display: none;
  }
`
