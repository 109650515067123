import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { ReactNode } from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { StateResultsProvided } from 'react-instantsearch-core'

interface Props extends StateResultsProvided {
  className?: string
  notLoadingElement?: ReactNode
  isSearchStalled: boolean
}

export const AlgoliaLoadingIndicator = connectStateResults<Props>(
  ({ isSearchStalled, className, notLoadingElement }) =>
    isSearchStalled ? (
      <SpinnerCentered className={className} />
    ) : (
      <>{notLoadingElement || null}</>
    )
)
