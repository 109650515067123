import { CarResponse } from 'driverama-core/api/driverama/stock/stockCarDetail'
import { safelyAssertUnreachable } from 'driverama-core/utils/types'
import { useTranslation } from 'react-i18next'

export type MarketingLabel = NonNullable<CarResponse['marketingLabel']>

export function useMarketingLabelResolver() {
  const { t } = useTranslation(['core'])

  return (label: MarketingLabel) => {
    switch (label) {
      case 'POPULAR_CHOICE':
        return t('core:marketing_label_popular_choice')
      case 'UNIQUE_CONFIGURATION':
        return t('core:marketing_label_unique_configuration')
      case 'NEW_ARRIVAL':
        return t('core:marketing_label_new_arrival')
      case 'NEW_PRICE':
        return t('core:marketing_label_new_price')
      case 'SEVEN_SEATER_FAMILY_CAR':
        return t('core:marketing_label_seven_seater_family_car')
      case 'PLUG_IN_HYBRID':
        return t('core:marketing_label_plugin_hybrid')
      case 'HYBRID':
        return t('core:marketing_label_hybrid')
      case 'ELECTRIC':
        return t('core:marketing_label_electric')
      case 'HOT_DEAL':
        return t('core:marketing_label_hot_deal')
      default:
        return safelyAssertUnreachable(label)
    }
  }
}

export function getMarketingLabelLabel(
  resolver: (label: MarketingLabel) => string | null,
  label: MarketingLabel
) {
  return resolver(label)
}

export function getMarketingLabelName(label: MarketingLabel) {
  switch (label) {
    case 'POPULAR_CHOICE':
      return 'FastSelling'
    case 'UNIQUE_CONFIGURATION':
      return 'UniqueConfiguration'
    case 'NEW_ARRIVAL':
      return 'NewArrival'
    case 'NEW_PRICE':
      return 'NewPrice'
    case 'SEVEN_SEATER_FAMILY_CAR':
      return 'SevenSeaterFamilyCar'
    case 'PLUG_IN_HYBRID':
      return 'PluginHybrid'
    case 'HYBRID':
      return 'Hybrid'
    case 'ELECTRIC':
      return 'Electric'
    case 'HOT_DEAL':
      return 'Hot deal'
    default:
      return undefined
  }
}
