import { LinearLoanCalculatorResponse } from 'driverama-core/api/driverama/loanCalculator/loanCalculator'
import { CarCardImageGradient } from 'driverama-core/components/carCard/CarCardImageGradient'
import { Dot } from 'driverama-core/components/Dot'
import { Flex } from 'driverama-core/components/Flex'
import { ImagePlaceholder } from 'driverama-core/components/imagePlaceholder/ImagePlaceholder'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Tag } from 'driverama-core/components/tag/Tag'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import IconInfo from 'driverama-core/images/icons/IconCircleInfo.svg'
import IconHeart from 'driverama-core/images/icons/IconHeart.svg'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { CarTag, tagVariant } from 'driverama-core/utils/tags'
import { isNotNil } from 'driverama-core/utils/types'
import Image from 'next/image'
import { forwardRef } from 'react'
import {
  SCard,
  SContent,
  SDetails,
  SHeart,
  SMarketingHighlights,
  SPrice,
  SPriceMonthly,
  SPriceMonthlyPlaceholder,
  STag
} from './CarCardGrid.styled'

interface Props {
  name: string
  image?: string
  isFinancingEnabled: boolean
  isSellingShutDown: boolean
  isCarPhotoGradientEnabled: boolean
  isFullWidth?: boolean
  carSource?: 'DRIVERAMA' | 'AURES'
  year?: number | null
  marketingHighlights?: string[]
  tag?: CarTag
  saved?: boolean
  toggleSaved?: () => void
  className?: string
  tagLabel?: string | null
  mileageLabel?: string
  priceLabel?: string
  priceMonthlyLabel?: string
  customTextLabel?: string
  openFinancingDisclaimerModal?: () => void
  loanData?: Omit<
    LinearLoanCalculatorResponse[number],
    'subjectPriceIncludingVatEur' | 'upsales'
  >
}

export const CarCardGrid = forwardRef<HTMLDivElement, Props>(
  (
    {
      name,
      year,
      image,
      marketingHighlights,
      tag,
      saved,
      toggleSaved,
      className,
      tagLabel,
      mileageLabel,
      priceLabel,
      priceMonthlyLabel,
      isSellingShutDown,
      isFinancingEnabled,
      carSource,
      customTextLabel,
      isCarPhotoGradientEnabled,
      isFullWidth = true,
      openFinancingDisclaimerModal
    },
    ref
  ) => {
    return (
      <SCard
        image={
          image ? (
            <>
              <Image
                src={image}
                width={465}
                height={350}
                layout="intrinsic"
                objectFit="cover"
                objectPosition={
                  carSource === 'DRIVERAMA' ? '0% 50%' : undefined
                }
              />

              {isCarPhotoGradientEnabled && (
                <CarCardImageGradient carSource={carSource} image={image} />
              )}
            </>
          ) : (
            <ImagePlaceholder />
          )
        }
        ref={ref}
        className={className}
        tag={
          tag && tagLabel && <STag variant={tagVariant(tag)}>{tagLabel}</STag>
        }
        isFullWidth={isFullWidth}
      >
        {toggleSaved && (
          <SHeart isActive={saved} onClick={toggleSaved}>
            <IconHeart />
          </SHeart>
        )}
        <SContent>
          <div>
            <TextHeader variant={['h6', 'h5']} as="h5">
              {name}
            </TextHeader>
            <Spacer axis="vertical" size={2} />
            <Flex variant="row" gap={2} align="center">
              {isNotNil(year) && (
                <>
                  <TextBody size="small">{year}</TextBody>
                  <Dot />
                </>
              )}
              {mileageLabel && <TextBody size="small">{mileageLabel}</TextBody>}
              {customTextLabel && (
                <>
                  <Dot />
                  <TextBody size="small">{customTextLabel}</TextBody>
                </>
              )}
            </Flex>
            <Spacer axis="vertical" size={2} />
            {marketingHighlights && (
              <SMarketingHighlights variant="row" wrap="wrap">
                {marketingHighlights.map(highlight => (
                  <Tag key={highlight} variant="tertiary">
                    {highlight}
                  </Tag>
                ))}
              </SMarketingHighlights>
            )}
          </div>
          <Spacer size={2} />
          <SDetails>
            {!isSellingShutDown && isFinancingEnabled && priceMonthlyLabel ? (
              <Flex variant="row" align="center">
                <SPriceMonthly color="night-l-100" size={['small']}>
                  {priceMonthlyLabel}
                </SPriceMonthly>

                <Spacer size={1} />

                <IconInfo
                  color={color('night-l-100')}
                  height={size(4)}
                  width={size(4)}
                  onClick={e => {
                    e.preventDefault()

                    openFinancingDisclaimerModal?.()
                  }}
                />
              </Flex>
            ) : (
              <SPriceMonthlyPlaceholder />
            )}

            {priceLabel && (
              <Flex variant="row" justify="end" align="center">
                <SPrice variant={['h6', 'h5']} as="h6">
                  {priceLabel}
                </SPrice>
              </Flex>
            )}
          </SDetails>
        </SContent>
      </SCard>
    )
  }
)
