import { css } from '@emotion/react'
import { AlgoliaLoadingIndicator } from 'components/algolia/loadingIndicator/AlgoliaLoadingIndicator'
import { useFinancingDisclaimerModal } from 'components/financing/disclaimer/FinancingDisclaimerModal'
import { LINKS } from 'constants/links'
import { useLovModelLinesSearchQuery } from 'driverama-core/api/driverama/lov/lovModelLinesSearch'
import { ButtonArrow } from 'driverama-core/components/buttonArrow/ButtonArrow'
import { CarCardGrid } from 'driverama-core/components/carCard/grid/CarCardGrid'
import { CarouselControls } from 'driverama-core/components/carousel/controls/CarouselControls'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader, TextSubhead } from 'driverama-core/components/text/Text'
import { layoutGrid } from 'driverama-core/styles/common'
import { resolveCarName } from 'driverama-core/utils/cars'
import { useCarousel } from 'driverama-core/utils/hooks'
import { CarTag } from 'driverama-core/utils/tags'
import { isNotNil } from 'driverama-core/utils/types'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { HitsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import { useWindowSize } from 'react-use'
import { AlgoliaStockCarResponse } from 'sections/serp/Serp.types'
import {
  CarDetailSourcePage,
  CarDetailSourceSection
} from 'utils/analytics/useLogEvents'
import { useMarketingHighlights } from 'utils/useMarketingHighlights'

import {
  getMarketingLabelLabel,
  getMarketingLabelName,
  useMarketingLabelResolver
} from 'utils/useMarketingLabel'
import { AlgoliaInsightWrapper } from '../insightWrapper/AlgoliaInsightWrapper'
import {
  SCarContainer,
  SContainer,
  SDesktopBrowseAll,
  SHeader,
  SList,
  SMobileBrowseAll,
  STrack
} from './CarCarousel.styled'

type Props = HitsProvided<AlgoliaStockCarResponse> & {
  hideTrack?: boolean
  hideBrowseAll?: boolean
  source: {
    page: CarDetailSourcePage
    section: CarDetailSourceSection
  }
  subtitle?: string
  title?: string
  emptyStateText?: string
  showMarketingLabels?: boolean
  isB2B?: boolean
}

function CarCarouselConnected(props: Props) {
  const windowSize = useWindowSize()
  const marginSize = Math.max(0, (windowSize.width - 1280) / 2)

  const { t } = useTranslation(['core'])
  const { childRefs, containerRef, pageState, handleArrowClick } = useCarousel<
    HTMLDivElement,
    HTMLDivElement
  >({
    rootMarginLeft: -marginSize,
    mounted: !!props.hits.length
  })

  const {
    financingDisclaimerModal,
    openFinancingDisclaimerModal
  } = useFinancingDisclaimerModal()

  const cars = props.hits
  const modelLineIds = cars.map(car => car.modelLineId).filter(isNotNil)

  const { data: modelLines } = useLovModelLinesSearchQuery(
    { filter: { ids: modelLineIds, yearFromIncludeNull: true } },
    { enabled: modelLineIds.length > 0 }
  )

  function getModelLine(car: AlgoliaStockCarResponse) {
    return modelLines?.content?.find(
      modelLine => modelLine.id === car.modelLineId
    )
  }

  function getCarName(car: AlgoliaStockCarResponse) {
    return resolveCarName({
      make: { id: car.makeId, name: car.makeName },
      model: { id: car.modelId, name: car.modelName },
      modelLine: {
        id: car.modelLineId || '',
        name: getModelLine(car)?.name
      },
      engine: { id: '', name: car.engineName }
    })
  }

  const marketingLabelResolver = useMarketingLabelResolver()
  const resolveMarketingHighlights = useMarketingHighlights()

  const browseAll = () => {
    if (props.source.page !== 'search_cars') {
      const linkToSerp =
        props.source.page === 'b2b'
          ? `${LINKS.carsSearch}?source=DRIVERAMA`
          : LINKS.carsSearch

      return (
        <Link href={linkToSerp} passHref>
          <a>
            <ButtonArrow text={t('core:new_arrivals_browse')} />
          </a>
        </Link>
      )
    }

    return null
  }

  return (
    <SContainer>
      <div css={layoutGrid}>
        {(props.title || props.subtitle) && (
          <SHeader>
            <TextSubhead>{props.subtitle}</TextSubhead>

            <Spacer axis="vertical" size={[1, 1, 3]} />

            <Flex variant="row" justify="between">
              <TextHeader variant={['h4', 'h3', 'h2']} as="h2">
                {props.title}
              </TextHeader>

              {!props.hideBrowseAll && (
                <SDesktopBrowseAll>{browseAll()}</SDesktopBrowseAll>
              )}
            </Flex>

            <Spacer axis="vertical" size={[0, 0, 7]} />
          </SHeader>
        )}

        {cars.length === 0 && (
          <div
            css={css`
              grid-column: 2 / -2;
            `}
          >
            <Spacer size={20} />

            <AlgoliaLoadingIndicator
              notLoadingElement={
                <TextHeader
                  as="h6"
                  variant={['h6', 'h5', 'h5']}
                  css={{ textAlign: 'center' }}
                >
                  {props.emptyStateText}
                </TextHeader>
              }
            />

            <Spacer size={20} />
          </div>
        )}

        <SList ref={containerRef}>
          {cars.map((car, i) => {
            const carPrices = car?.prices?.[0]

            const priceMonthlyLabel =
              !props.isB2B && carPrices?.loan?.monthlyInstallmentEurRounded
                ? t('core:eur_price_monthly_from', {
                    price: carPrices.loan.monthlyInstallmentEur
                  })
                : undefined

            return (
              <SCarContainer
                key={i}
                ref={node => {
                  if (node) {
                    childRefs.current[i] = node
                    node.dataset.page = `${i + 1}`
                  }
                }}
              >
                <AlgoliaInsightWrapper
                  // @ts-expect-error external types are ommited by instant-search :/
                  hit={car}
                  source={props.source}
                >
                  <CarCardGrid
                    name={getCarName(car)}
                    year={car.firstRegistrationYear}
                    carSource={car.source}
                    image={car.photos?.find(photo => photo.order === 1)?.url}
                    mileageLabel={t('core:mileage', {
                      n: car.actualMileageKm
                    })}
                    priceLabel={t('core:eur_price_decimal', {
                      n: car.prices?.[0]?.fullPriceEur
                    })}
                    priceMonthlyLabel={priceMonthlyLabel}
                    isFinancingEnabled={
                      driverama.flags.IS_NEW_FINANCING_CAR_DETAIL_ENABLED
                    }
                    isSellingShutDown={driverama.flags.IS_SELLING_SHUT_DOWN}
                    marketingHighlights={
                      car.marketingHighlights
                        ? resolveMarketingHighlights(car.marketingHighlights)
                        : undefined
                    }
                    isCarPhotoGradientEnabled={
                      driverama.flags.IS_CAR_PHOTO_GRADIENT_ENABLED
                    }
                    isFullWidth={false}
                    tagLabel={
                      props.showMarketingLabels && car.marketingLabel
                        ? getMarketingLabelLabel(
                            marketingLabelResolver,
                            car.marketingLabel
                          )
                        : undefined
                    }
                    tag={
                      props.showMarketingLabels && car.marketingLabel
                        ? (getMarketingLabelName(car.marketingLabel) as CarTag)
                        : undefined
                    }
                    openFinancingDisclaimerModal={() => {
                      if (isNotNil(carPrices) && isNotNil(carPrices.loan)) {
                        openFinancingDisclaimerModal(carPrices.loan)
                      }
                    }}
                  />
                </AlgoliaInsightWrapper>
              </SCarContainer>
            )
          })}
        </SList>

        {!props.hideTrack && <STrack />}
        {!!cars.length && (
          <CarouselControls
            items={cars}
            pageState={pageState}
            handleArrowClick={handleArrowClick}
          />
        )}
        <Spacer axis="vertical" size={20} />

        {!props.hideBrowseAll && (
          <SMobileBrowseAll>{browseAll()}</SMobileBrowseAll>
        )}
      </div>
      {financingDisclaimerModal}
    </SContainer>
  )
}

export const CarCarousel = connectHits<Props, AlgoliaStockCarResponse>(
  CarCarouselConnected
)
