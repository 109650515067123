import { useMemo } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { searchClient } from 'sections/serp/Serp.utils'
import {
  CarDetailSourcePage,
  CarDetailSourceSection
} from 'utils/analytics/useLogEvents'
import { MarketingLabel } from 'utils/useMarketingLabel'
import { HydrateProps } from '../AlgoliaHydrate'
import { CarCarousel } from '../carCarousel/CarCarousel'

export function CarHighlightCarousel(
  props: HydrateProps & {
    isB2B?: boolean
    index?: string
    marketingLabel?: MarketingLabel
    hideTrack?: boolean
    hideBrowseAll?: boolean
    source: {
      page: CarDetailSourcePage
      section: CarDetailSourceSection
    }
    subtitle?: string
    title?: string
    emptyStateText?: string
    multiIndex?: boolean
    showMarketingLabels?: boolean
  }
) {
  const renderContent = useMemo(
    () => (
      <>
        <Configure
          filters={
            props.marketingLabel
              ? `marketingLabel:${props.marketingLabel}`
              : undefined
          }
          hitsPerPage={12}
          enablePersonalization
          clickAnalytics
        />

        <CarCarousel
          isB2B={props.isB2B}
          source={props.source}
          hideTrack={props.hideTrack}
          hideBrowseAll={props.hideBrowseAll}
          subtitle={props.subtitle}
          title={props.title}
          emptyStateText={props.emptyStateText}
          showMarketingLabels={props.showMarketingLabels}
        />
      </>
    ),
    [
      props.emptyStateText,
      props.hideBrowseAll,
      props.hideTrack,
      props.marketingLabel,
      props.showMarketingLabels,
      props.source,
      props.subtitle,
      props.title,
      props.isB2B
    ]
  )
  if (props.multiIndex) {
    return (
      <Index
        indexName={
          props.index ??
          (process.env.NEXT_PUBLIC_ALGOLIA_STOCK_CARS_INDEX as string)
        }
        indexId={`highlight-${props.marketingLabel}`}
      >
        {renderContent}
      </Index>
    )
  }

  return (
    <InstantSearch
      resultsState={props.resultsState}
      widgetsCollector={props.widgetsCollector}
      indexName={
        props.index ??
        (process.env.NEXT_PUBLIC_ALGOLIA_STOCK_CARS_INDEX as string)
      }
      searchClient={searchClient}
    >
      {renderContent}
    </InstantSearch>
  )
}
