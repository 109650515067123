import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { CarCardSmall } from 'driverama-core/components/carCard/small/CarCardSmall'
import { Flex } from 'driverama-core/components/Flex'
import { Tag } from 'driverama-core/components/tag/Tag'
import { TextBodyMedium, TextHeader } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const SCard = styled(CarCardSmall)<{ isFullWidth: boolean }>`
  position: relative;
  height: 100%;

  ${({ isFullWidth }) =>
    !isFullWidth &&
    css`
      max-width: 290px;
    `}
`

export const STag = styled(Tag)`
  position: absolute;
  top: ${size(4)};
  left: ${size(4)};
`

export const SContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;

  height: 100%;
`

export const SDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const SPriceMonthly = styled(TextBodyMedium)`
  text-decoration: underline;
  cursor: default;
`

export const SPriceMonthlyPlaceholder = styled.div`
  width: 0;
`

export const SPrice = styled(TextHeader)`
  color: ${color('night-l-100')};
`

export const SMarketingHighlights = styled(Flex)`
  gap: ${size(2)};
`

export const SHeart = styled(ButtonTransparent)<{ isActive?: boolean }>`
  position: absolute;
  top: ${size(4)};
  right: ${size(4)};

  ${({ isActive }) => css`
    color: ${color(isActive ? 'warning' : 'night-text')};

    svg {
      fill: ${isActive ? color('warning') : 'none'};
    }
  `}

  && {
    margin: 0;
  }
`
